export const hasBodyClass = (className) => {
  return document.body.classList.contains(className)
}

export default {
  /**
   * Emit a custom event
   * @param  {String} type   The event type
   * @param  {Object} detail Any details to pass along with the event
   * @param  {Node}   elem   The element to attach the event to
   */
  emitEvent(type, detail = {}, elem = document) {
    if (!type) return

    let event = new CustomEvent(type, {
      bubbles: true,
      cancelable: true,
      detail: detail
    })

    return elem.dispatchEvent(event)
  },
  randomNumber(min = 0, max = 1000) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },
  debounce(fn, wait) {
    let t
    return (...args) => {
      clearTimeout(t)
      t = setTimeout(() => fn.apply(this, args), wait)
    }
  },
  truncateLongTitle(input) {
    return input.length > 5 ? `${input.substring(0, 18)}...` : input
  },
  arraysAreEqual(arr1, arr2) {
    return (
      arr1.length === arr2.length &&
      arr1.reduce((acc, currentValue, index) => acc && currentValue === arr2[index], true)
    )
  },
  mutateArray(array, index, newValue) {
    return array.map((value, i) => (i === index ? newValue : value))
  },
  logError(message) {
    console.error(message)
  },
  throwError(message) {
    throw new Error(message)
  },
  parseJson(el, attribute) {
    const jsonEl = el.querySelector(attribute)
    if (!jsonEl) {
      console.error(`JSON element with attribute ${attribute} not found`)
      return null
    }
    try {
      return JSON.parse(jsonEl.textContent)
    } catch (error) {
      this.logError(`Error parsing JSON: ${error}`)
      return null
    }
  },
  findSelectedVariant(variants, options) {
    return variants.find((variant) => variant.options.every((option) => options.includes(option)))
  },
  async fetchHTML(endpoint) {
    return await fetch(endpoint)
      .then((response) => response.text())
      .then((responseText) => {
        return new DOMParser().parseFromString(responseText, 'text/html')
      })
  },
  // todo: clean this money formatting mess up
  formatCurrency(currency, decimals = 2, locale = 'de-DE') {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });

    return (amount) => `${currency}${formatter.format(amount)}`;
  },
  parseShopifyPrice(amount, deciamals = 2) {
    return Number((amount / 100).toFixed(deciamals))
  },
  parseMoneyString(moneyString) {
    if (typeof moneyString !== 'string' || !moneyString.length) {
      // console.error("money isn't a string")
      return false
    }

    const trimmedString = moneyString.trim().replace(/,/g, '.')

    // Check if the string is not empty after trimming
    if (!trimmedString) {
      console.error("trimmed money string has no length")
      return false
    }

    // Use a regular expression to match currency symbols and split the string
    const match = trimmedString.match(/([^\d]*)(\d+\.?\d*)/)

    if (match) {
      const currency = match[1]
      const amount = parseFloat(match[2])

      return {
        currency,
        amount: isNaN(amount) ? 0 : amount // Ensure amount is a valid number
      }
    }

    return {}
  },
  toggleClass(element, className, add) {
    if (add) {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
  },
  onKeyUpEscape(event) {
    if (event.code.toUpperCase() !== 'ESCAPE') return;

    const openDetailsElement = event.target.closest('details[open]');
    if (!openDetailsElement) return;

    const summaryElement = openDetailsElement.querySelector('summary');
    openDetailsElement.removeAttribute('open');
    summaryElement.setAttribute('aria-expanded', false);
    summaryElement.focus();
  }
}
