export default {
  name: "paginationHandler",
  component() {
    return {
      init() {
        this.$el.addEventListener('click', handlePaginationClick.bind(this));
      }
    }
  }
};

function handlePaginationClick(event) {
  if (event.target.href) {
    event.preventDefault();

    this.$wire(event.target.href, '#target-collection', '#target-collection', 'outerHTML', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      window.history.replaceState({}, '', event.target.href);
    });
  }
}