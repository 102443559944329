export default {
  name: 'customiser',
  component() {
    const productFormEl = document.querySelector('ajax-cart-product-form form')
    function handleFormSubmit() {
      try {
        if (productFormEl) {
          productFormEl.dispatchEvent(new Event('submit'))
        } else {
          console.error('Cart form not found.')
        }
      } catch (error) {
        console.error(error.message)
      }
    }
    return {
      step: 0,
      totalSteps: 0,
      submitStep: null,
      theme: null,
      initCustomizer(totalStepsCount) {
        this.totalSteps = totalStepsCount
        const bodyEl = document.querySelector('body');
        if (bodyEl.classList.contains('BXC')) {
          this.theme = 'BXC'
          this.submitStep = this.totalSteps
        } else {
          this.submitStep = this.totalSteps - 1
        }
      },
      prevStep() {
        try {
          if (this.step === this.totalSteps) {
            this.step = this.totalSteps - 1
            return
          }
          if (this.step > 0) {
            this.step -= 1
          }
        } catch (error) {
          console.error(error.message)
        }
      },
      // 💡 This dummy snippet should prevent the user to progress in the customiser without clone option selected (if it's visible)
      testIfCloneOptionSelected() {
        const currentCPIdx = this.step - 1;
        if (currentCPIdx < 0) return true;

        const currentCPEntry = Object.entries(window.Alpine.store('crossProducts').current)[currentCPIdx];
        if (!currentCPEntry) return true;

        const [currentCPId, cpData] = currentCPEntry;

        const cloneEl = document.querySelector(`fieldset[data-cross-product-id="${currentCPId}"][data-clone]`);
        if (!cloneEl || cloneEl.classList.contains('!hidden')) return true;

        return !!cpData?.cloneVariantId;
      },
      nextStep() {
        try {
          if (this.step === this.submitStep) {
            this.handleSubmit()
            return
          }

          if (this.step < this.totalSteps && this.testIfCloneOptionSelected()) {
            this.step += 1
          }
        } catch (error) {
          console.error(error.message)
        }
      },
      goto(step) {
        try {
          if (this.step != step && step > -1 && step <= this.totalSteps) {
            this.step = step
            return
          }

        } catch (error) {
          console.error(error.message)
        }
      },
      reset() {
        try {
          this.step = 0
          // ❗️ enable for reset cross products after form submission - bugged
          // window.Alpine.store('crossProducts')?.resetCrossProduct()
        } catch (error) {
          console.error(error.message)
        }
      },
      handleSubmit() {
        try {
          handleFormSubmit()
        } catch (error) {
          console.error(error.message)
        }
      },
      initConfigurator(currentMainVariantId) {
        const configurator = this.$refs.configurator
        const cps = Alpine.store('crossProducts').current
        const cpArr = Object.entries(cps)
        let crossVariantIdArr = []
        for (let i = 0; i < cpArr.length; i++) {
          crossVariantIdArr.push(cpArr[i][1].variant.id)
        }

        return this.$nextTick(() => {
          configurator.onConfiguratorLoaded(() => {
            // console.info('Configrator ready, listing 360 assets:')
            const groupNames = this.$el.groupNames
            // console.info('Group names', groupNames)
            if (groupNames?.length) {
              // console.info(`Model names in ${groupNames[0]}`, configurator.modelNames(groupNames[0]))
              // console.info(`Model names in ${groupNames[1]}`, configurator.modelNames(groupNames[1]))
              console.info(`Model names in ${groupNames[2]}`, configurator.modelNames(groupNames[2]))
              // console.info('------')
              // console.info(`Material names in ${groupNames[0]}`, configurator.materialNames(groupNames[0]))
              // console.info(`Material names in ${groupNames[1]}`, configurator.materialNames(groupNames[1]))
              // console.info(`Material names in ${groupNames[2]}`, configurator.materialNames(groupNames[2]))
            }
            try {
              this.handleLoadDefaultModels(currentMainVariantId, crossVariantIdArr)
            } catch (error) {
              alert(error)
              return
            }
          });
        })
      },
      switchModel(modelName, groupIdx = 1) {
        const groupNames = this.$refs.configurator.groupNames
        this.$refs.configurator.switchGroupModel(groupNames[groupIdx], modelName)
        // if (!groupNames?.length) {
        //   console.error('group names not found')
        // } else {
        //   console.log('G names', groupNames)

        //   let modelName = ''
        //   if (modelNameObj?.hasOwnProperty('size')) {
        //     const matchedSize = modelNameObj.size.replace(/X/g, "x")
        //     modelName += matchedSize
        //   }

        //   modelName += '-Single'
        //   this.$refs.configurator.switchGroupModel(groupNames[groupIdx], modelName)
        // }
      },
      switchMaterial(materialName, groupIdx = 1) {
        const groupNames = this.$refs.configurator.groupNames
        this.$refs.configurator.switchGroupMaterial(groupNames[groupIdx], materialName)
        // if (!groupNames?.length) {
        //   console.error('group names not found')
        // } else {
        //   let modelName = ''
        //   if (modelNameObj.hasOwnProperty('productName')) {
        //     const matchedProductName = modelNameObj.productName.split(" ")[1]
        //     modelName += matchedProductName
        //   }
        //   if (modelNameObj.hasOwnProperty('color')) {
        //     modelName += ' ' + modelNameObj.color
        //   }

        //   modelName += ' Boxspring'
        //   this.$refs.configurator.switchGroupMaterial(groupNames[groupIdx], modelName)
        // }
      },
      handleLoadDefaultModels(currentMainVariantId, currentCpArr) {
        // main
        this.$refs.configurator.setShopifyVariant(currentMainVariantId)
        // CPs
        if (currentCpArr.length) {
          currentCpArr.forEach((cpId) => this.$refs.configurator.setShopifyVariant(cpId))
        }
      }
    }
  }
}
