export default {
  name: 'handleSamplePage',
  component() {
    return {
      init() {
        const variantsEl = this.$el.querySelector('script[type="application/json"][data-sample-variants]')
        if (variantsEl) {
          try {
            const variantsData = JSON.parse(variantsEl.textContent);
            if (Array.isArray(variantsData)) {
              this.variants = variantsData;
            }
          } catch (e) {
            console.error('Error parsing JSON data:', e);
          }
        }
      },
      variants: [],
      selectedIds: [],
      imgUrls: {},
      max: 5,
      toggleItem(id, imgUrl) {
        if (this.selectedIds.includes(id)) {
          this.selectedIds = this.selectedIds.filter((i) => i !== id);
          delete this.imgUrls[id]
        } else if (this.selectedIds.length < this.max) {
          this.selectedIds.push(id);
          this.imgUrls[id] = imgUrl
        }
      },
      handleItemClasses(id) {
        return {
          'opacity-100': this.selectedIds.includes(id),
          '!cursor-not-allowed': !this.selectedIds.includes(id) && this.selectedIds.length == this.max,
        };
      },
      removeItem(index) {
        this.selectedIds.splice(index, 1);
      },
      findVariant(id) {
        return { sampleVariant: this.variants.find((vari) => vari.id === id), imgUrl: this.imgUrls[id] }
      },
      setSwatchColor(id) {
        const { sampleVariant, imgUrl } = this.findVariant(id);
        if (sampleVariant) {
          const variantColor = sampleVariant.option1?.toLowerCase();
          if (variantColor) {
            if (imgUrl) {
              this.$el.style = `background-image:url(${imgUrl});background-size: 33.33% 33.33%; background-repeat: repeat;"`;
            } else {
              this.$el.style.backgroundColor = variantColor;
            }

          }
        }
      }
    }
  }
}