export default {
  name: 'crossProductPicker',
  component(crossProductId, defaultOptions, currentMainVariantId, mayHasClone) {
    const crossProductStore = window.Alpine.store('crossProducts')
    const helpers = window.sourcherry.helpers

    if (!crossProductStore) {
      throw new Error('Cross store not found.')
    }
    if (!defaultOptions?.length || defaultOptions.length > 3 || typeof defaultOptions[0] !== 'string') {
      console.error('Options not found or empty')
      return
    }
    if (!currentMainVariantId) {
      console.error('Picker cant find main vari id')
      return
    }

    return {
      options: [],
      availableOptions: {
        option2: null,
        option3: null
      },
      outOfStockOptions: {
        option1: null,
        option2: null,
        option3: null
      },
      customiserSettings: {},
      init() {
        this.initComponent(currentMainVariantId)

        this.$watch('$store.crossProducts.mainVariantId', (state, oldState) => {
          if (oldState) {
            this.initComponent(state)
          }
        })
      },

      initComponent(currentMainVariantId) {
        const res = crossProductStore.registerCrossProduct(
          this.$el,
          crossProductId,
          currentMainVariantId,
          defaultOptions,
          mayHasClone
        )
        this.options = res.options
        this.availableOptions = res.availableOptions
        this.outOfStockOptions = res.outOfStockOptions
        this.activeRule = res.activeRule

        if (Object.keys(res.customiserSettings)?.length) {
          this.customiserSettings = res.customiserSettings
        }

        const inputEls = this.$el.querySelectorAll(`input`)
        inputEls.forEach((inputEl) => {
          const optionIDX = inputEl.dataset?.idx
          const isClone = !!inputEl.dataset?.clone

          // if (isClone && optionIDX && this.options[optionIDX]) {
          //   inputEl.checked = this.options[optionIDX] == inputEl.value
          // }

          if (!isClone && this.options.includes(inputEl.value)) {
            if (!inputEl.checked) {
              inputEl.checked = true
            }
          }
        })

        crossProductStore.updateCustomiserPriceLabels()
      },

      handleUserInput(ev) {
        const idx = parseInt(ev.target.dataset?.idx)
        const posi = parseInt(ev.target.dataset?.posi)
        const isClone = !!ev.target.dataset?.clone
        const value = ev.target.value
        const currentProduct = crossProductStore.current[crossProductId]

        if (!Number.isInteger(idx) || idx < 0 || idx > 2 || !value) {
          console.error('Invalid index or value', idx, value)
          return
        }

        if (!currentProduct) {
          throw new Error('cross product not found')
        }

        if (isClone) {
          const newCloneOptions = helpers.mutateArray(this.options, idx, value)
          const cloneVariant = helpers.findSelectedVariant(currentProduct.variants, newCloneOptions)
          const cloneVariantId = cloneVariant?.id

          crossProductStore.updateCrossProductClone(crossProductId, newCloneOptions, cloneVariantId)

          crossProductStore.updateExtraOptionLabels(
            crossProductId,
            this.options,
            this.outOfStockOptions,
            this.availableOptions,
            isClone
          )

          const customiserSummaryItemEl = document.querySelector(`li[data-cp-option-idx='${idx}'][data-clone]`)
          customiserSummaryItemEl.classList.remove('hidden')
          customiserSummaryItemEl.style.display = 'flex'
          const valueEl = customiserSummaryItemEl.querySelector('p[data-value]')

          if (valueEl) {
            valueEl.textContent = value
          } else {
            console.info('customiser summary value not found')
          }
          return
        } else {
          const customiserSummaryEl = document.querySelector(`li[data-cp-id='${crossProductId}'][data-cp-option-idx='${idx}'] p[data-value]`)

          if (customiserSummaryEl) {
            customiserSummaryEl.textContent = value
          } else {
            console.error('customiser summary value not found')
          }
        }

        this.options = helpers.mutateArray(this.options, idx, value)
        const res = crossProductStore.updateCrossProductVariant(crossProductId, this.options, posi, isClone)

        if (res.availableOptions && res.outOfStockOptions) {
          this.availableOptions = res.availableOptions
          this.outOfStockOptions = res.outOfStockOptions
          this.activeRule = res.activeRule
        }

        if (this.customiserSettings && res.activeVariantId) {
          const cpContainer = event.target.parentNode.parentNode
          const hiddenFieldsets = cpContainer ? Array.from(cpContainer.querySelectorAll('[data-clone]')) : []
          const hiddenCustomiserFieldsets = Array.from(
            document.getElementById('product-customiser').querySelectorAll(`fieldset[data-clone]`)
          )
          const allHiddenFieldsets = [...hiddenCustomiserFieldsets, ...hiddenFieldsets]
          const hiddenLabels = document.querySelectorAll(`span[for='${crossProductId}']`)

          const { hasSuggestedQuantity } = this.findActiveCustomiserSettings(
            this.customiserSettings,
            res.activeVariantId
          )

          if (!hasSuggestedQuantity) {
            crossProductStore.updateCrossProductClone(crossProductId, [], null)
          }

          allHiddenFieldsets.forEach((fieldset) => helpers.toggleClass(fieldset, '!hidden', !hasSuggestedQuantity))
          hiddenLabels.forEach((label) => helpers.toggleClass(label, 'hidden', !hasSuggestedQuantity))

          // temp
          const configurator = document.getElementById('configurator')
          if (configurator) {
            try {
              // 💡 SET 360 Fabriek variant id
              configurator.setShopifyVariant(res.activeVariantId)
            } catch (error) {
              alert(error)
            }
          }
        }

        crossProductStore.updateCustomiserPriceLabels()
      },
      findActiveCustomiserSettings(settings, activeVariantId) {
        const activeSettingEntries = Object.entries(settings).find(
          ([variantId, settingVal]) => variantId == activeVariantId
        )
        const hasSuggestedQuantity = activeSettingEntries?.[1]?.hasOwnProperty('suggested_quantity')
        return { hasSuggestedQuantity }
      },
      // 💡 for handling base PDP input + label, could be merged with extra labels solution
      handleUnavailable(optionPosition, value) {
        const avail = this.availableOptions?.[`option${optionPosition}`]

        if (avail == null) {
          return false
        } else if (avail.length === 0) {
          return true
        }

        const isUnavailable = avail.includes(value)

        return !isUnavailable
      },
      // 💡 for handling base PDP input + label, could be merged with extra labels solution
      handleOutOfStock(optionPosition, value) {
        const outOfS = this.outOfStockOptions?.[`option${optionPosition}`]
        if (outOfS?.length > 0) {
          if (outOfS.includes(value)) {
            return 'out-of-stock'
          }
        }
      },

      handleComponentState(optionPosition, value) {
        let classes = ''
        const outOfS = this.outOfStockOptions?.[`option${optionPosition}`]
        if (outOfS?.length > 0) {
          if (outOfS.includes(value)) {
            classes += 'out-of-stock'
          }
        }

        const avail = this.availableOptions?.[`option${optionPosition}`]
        if (avail == undefined) {
          return classes
        }
        if (avail?.length === 0 || !avail?.includes(value)) {
          classes += 'invalid-option disabled'
        }

        return classes
      }
    }
  }
}
